/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var g_SlideshowVisible = false;
var g_SlideshowMaxiMode = false;

(function($) {

  function initScrollbarHorizontal(){
    "use strict";
    $(".horizontalscrollcontainer").mCustomScrollbar({
      //horizontalScroll:true,
      axis:"x",
      //autoDraggerLength:false,
      //scrollInertia:200,
      autoHideScrollbar: true,
      scrollButtons:{enable:false, scrollSpeed: 25},
      mouseWheel:{ enable: true, scrollAmount: 500 },
      advanced:{updateOnContentResize: true},
      contentTouchScroll:150
    });
  }

  function showImage(e) {

    var target = $( e.target );
    var src = target.attr('src');
    var ext = src.substr(src.lastIndexOf("."), src.length);
    console.log(ext);
    src = src.substr(0, src.lastIndexOf("-")) + ext;
    $('body').append('<div id="popup"></div>');
    $('div#popup').show();
    $('div#popup').css('visibility', 'visible');
    $('div#popup').html(
    '<div class="imgcont"><img src="' + src + '" ></div>'
    );
    $("#popup").click(function(event){
      $(this).css('visibility', 'hidden');
      $('div#popup').remove();
      event.preventDefault();
    });
  }



  /******************************************************
  *
  * LOGIN
  *
  *******************************************************/

  function setupLoginPopup() {
    $('.vera_bp_login_menu_button a').click(function(event) {
      event.preventDefault();
      $('#vera_bp_login').fadeIn(300);
      $('#vera_bp_login').toggleClass('active');
      event.stopPropagation();
    });

    $(document).mouseup(function (e) {
        var container = $("#vera_bp_login");
      if (!container.is(e.target) && container.has(e.target).length === 0) // ... nor a descendant of the container
      {
        container.hide();
      }
    });
  }

  /******************************************************
  *
  * Background Image
  *
  *******************************************************/

  function setupBackgroundImage() {
    var bgImageBase = $('#backgroundimage').data('image');
    var bgIndex = Math.floor(Math.random() * 5) + 1;
    //var History = window.History;
    var state = History.getState();
    if( Object.keys(state.data).length === 0) {
      History.replaceState({bgImageIndex:bgIndex},document.title,History.getState().url);
    }
    else {
      bgIndex = state.data.bgImageIndex;
    }
    var bgImage = bgImageBase + bgIndex + '.jpg';
    $('#backgroundimage').css('background-image', 'url(' + bgImage + ')');
  }

  var g_bgFullContainer;
  //var History;

  // Stretch all the full width rows
  var bgStretchTopImage = function () {
    $( '#backgroundimageparent' ).each( function () {
      var $$ = $( this );
      // Reset all the styles associated with row stretching
      $$.css( {
        'margin-left': 0,
        'margin-right': 0,
        'margin-top': 0,
        //'padding-left': 0,
        //'padding-right': 0,
        //'padding-top': 0
      } );

      var leftSpace = $$.offset().left - g_bgFullContainer.offset().left,
        rightSpace = g_bgFullContainer.outerWidth() - leftSpace - $$.parent().width(),
        topSpace = $$.parent().offset().top;

      $$.css({
        'margin-left': - leftSpace,
        'margin-right': - rightSpace,
        'margin-top': - topSpace,
        //'padding-left': $$.data( 'stretch-type' ) === 'full' ? leftSpace : 0,
        //'padding-right': $$.data( 'stretch-type' ) === 'full' ? rightSpace : 0
      } );
    } );
  };

  // Stretch all the full width rows
  var bgStretchRow = function () {
    $( '.full-row-stretched' ).each( function () {
      var $$ = $( this );
      $$.css( {
        'left': 0,
        'right': 0,
      } );

      var leftSpace = $$.offset().left - g_bgFullContainer.offset().left,
        rightSpace = g_bgFullContainer.outerWidth() - leftSpace - $$.parent().width();
      $$.css({
        'left': - leftSpace,
        'right': - rightSpace,
        'padding-left': leftSpace,
        'padding-right': rightSpace,
        'width': g_bgFullContainer.width(),
        'opacity': 1,
      } );
    } );
  } ;


  /******************************************************
  * SLIDESHOW
  *******************************************************/

  function hideSlideshow(){
    "use strict";
    $(".slideshow-dark-bg").unbind('click');
    $(".cycle-slideshow-container").addClass("slideshow-vis");
    $("body").removeClass("no-overflow");
    g_SlideshowVisible = false;
  }

  function calculateSlideshowImageFitting() {

    "use strict";

    if(g_SlideshowVisible === false || g_SlideshowMaxiMode === true) {
      return;
    }

    var width = $('.cycle-slideshow').innerWidth();
    if(width === 0) {width = jQuery(window).width();}
    var height = $('.cycle-slideshow').height();
    var aspectRatioWindow = height/ width;
    $('.cycle-slide img').each(function(){
      var imageAR = $(this).data('aspectratio');
      if(imageAR <= aspectRatioWindow) {
        $(this).css({'width':'100%','height':'auto'});
      }
      else {
        $(this).css({'height':'100%','width':'auto'});
      }
    });
  }

  function showFullSize(){
    g_SlideshowMaxiMode = true;
    $(".btnCycleGroup").addClass('hidden');
    $(".btnCycleZoomOutMaxi").removeClass('hidden');
    var index = $('.cycle-slideshow').data("cycle.opts").currSlide;
    var img = $('.cycle-slideshow').find(".cycle-slide-active img");
    img.css({'width':'auto', 'height':'auto', 'padding':'0', 'margin':'0'});
    img.addClass('cursor-move');

    img.drag("start",function(){
      //img.css({'cursor':'-moz-grabbing', 'cursor':'-webkit-grabbing', 'cursor':'grabbing', 'cursor':'pointer'});
      img.addClass('cursor-drag');
    })
    .drag(function( ev, dd ){
      var preTop = dd.offsetY - img.parent().offset().top;
      var preLeft = dd.offsetX;
      jQuery( this ).css({ top:preTop, left:preLeft });
      //jQuery( this ).css({ cursor:pointer });
      
    })
    .drag("end",function(){
      //img.css({ 'cursor':'' });
      img.removeClass('cursor-drag');
    });
  }

  function removeFullSize(){

    var img = $('.cycle-slideshow').find(".cycle-slide-active img");
    img.unbind('drag');
    img.unbind('dragstart');
    img.unbind('dragend');

    img.css({"top":"", "left":"", "padding":"", "margin":"", "cursor":""});
    img.removeClass('cursor-move');
    $(".btnCycleGroup").removeClass('hidden');
    $(".btnCycleZoomOutMaxi").addClass('hidden');
    g_SlideshowMaxiMode = false;
    calculateSlideshowImageFitting();
  }

  function showSlideshow(id){
  "use strict";
    g_SlideshowVisible = true;

    $("body").addClass("no-overflow");

    // set last slide to transparent to prevent flickering of last slide when slideshow is showing up
    var lastSlide = $(".cycle-slide-active");
    lastSlide.css('opacity', 0);

    var slideshow = $('.cycle-slideshow');
    slideshow.css('opacity', 0);
    var opts = slideshow.data('cycle.opts');
    // change speed for *one* transition
    slideshow.one('cycle-before', function(e, slideOpts) {
      slideOpts.speed = 1;
      slideOpts.manualSpeed = 1;
    });
    opts.API.jump( id, 'none' );
    slideshow.resize();
    //use the Timer to let the slide go to the new position, then show the image.

    $(".slideshow-dark-bg").on('click', function(){
      hideSlideshow();
    });

    $(".cycle-slideshow-container").removeClass("slideshow-vis");
    calculateSlideshowImageFitting();


    window.setTimeout(function() {lastSlide.css('opacity', '');}, 10);
    window.setTimeout(function() {slideshow.fadeTo( 300, 1 );}, 10);
  }

  
  function gallerySetup() {

    $(".gallery img").click(function(event){
      showImage(event);
      event.preventDefault();
    });


    //on projectpage prepare entering the slidebox
    $(".pre-slidebox").click(function(event){
      if($(event.target).hasClass("slideshow")) {
        var id = event.target.id.replace( /^\D+/g, '');
        showSlideshow(id);
        event.preventDefault();
      }

    });

    $(".btnCycleZoomIn").click(function(event){
      if(!g_SlideshowMaxiMode) {
        showFullSize();
      }
      event.preventDefault();
    });

    $(".btnCycleZoomOut").click(function(event){
      hideSlideshow();
      event.preventDefault();
    });
    
    $(".btnCycleZoomOutMaxi").click(function(event){
      removeFullSize();
      event.preventDefault();
    });

    $('.cycle-slideshow').on('cycle-before',function(e, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag){
      removeFullSize();
    });
    
    $(".btnCycleGroup.next").hover(function(event){
      $(".btnCycleSubGroup.next").css({'right': '0'});
      event.preventDefault();
    }, function(event){
      $(".btnCycleSubGroup.next").css({'right': ''});
      event.preventDefault();
    });
    
    $(".btnCycleGroup.prev").hover(function(event){
      $(".btnCycleSubGroup.prev").css({'left': '0'});
      event.preventDefault();
    }, function(event){
      $(".btnCycleSubGroup.prev").css({'left': ''});
      event.preventDefault();
    });

    $( window ).resize(calculateSlideshowImageFitting);

  }


  /******************************************************
  *
  * ROUTER
  *
  *******************************************************/

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        g_bgFullContainer = $( 'body' );
        bgStretchRow();
        $( window ).resize( bgStretchRow ).load( bgStretchRow );

        initScrollbarHorizontal();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        setupLoginPopup();
        gallerySetup();
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        /**
        *  check for IE(11) to enable css hacks for the main metal menu
        *  console.log(navigator.userAgent);
        *  https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
        *  https://jsfiddle.net/311aLtkz/
        **/
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        if(isIE === true) {
          $('html').addClass('ie');
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        /** BG image **/
        setupBackgroundImage();
        g_bgFullContainer = $( 'body' );
        $( window ).resize( bgStretchTopImage ).load( bgStretchTopImage );
        bgStretchTopImage();
        /** end BG image **/


      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.





